import { default as indexuZ6Q6msChSMeta } from "/vercel/path0/pages/admin/chatbot/index.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as indexTWLF5PkbLpMeta } from "/vercel/path0/pages/admin/itens/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
export default [
  {
    name: indexuZ6Q6msChSMeta?.name ?? "admin-chatbot",
    path: indexuZ6Q6msChSMeta?.path ?? "/admin/chatbot",
    meta: indexuZ6Q6msChSMeta || {},
    alias: indexuZ6Q6msChSMeta?.alias || [],
    redirect: indexuZ6Q6msChSMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/chatbot/index.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexTWLF5PkbLpMeta?.name ?? "admin-itens",
    path: indexTWLF5PkbLpMeta?.path ?? "/admin/itens",
    meta: indexTWLF5PkbLpMeta || {},
    alias: indexTWLF5PkbLpMeta?.alias || [],
    redirect: indexTWLF5PkbLpMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/itens/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  }
]