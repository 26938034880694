import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import swiper_wnFZ5tA1pM from "/vercel/path0/.nuxt/swiper.mjs";
import nuxt_plugin_03YjkxYbK5 from "/vercel/path0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import vuex_owYp5qnaH8 from "/vercel/path0/plugins/vuex.js";
import plugin_client_LRRE9o5vvF from "/vercel/path0/.nuxt/nuxt-speedkit/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  swiper_wnFZ5tA1pM,
  nuxt_plugin_03YjkxYbK5,
  vuex_owYp5qnaH8,
  plugin_client_LRRE9o5vvF
]